<template>
  <div class="flex flex-row">
    <UFormGroup label="Rede" class="w-full" name="retail_chain.uuid">
      <USelectMenu
        v-model="form.retail_chain"
        :searchable="true"
        placeholder="Selecione uma rede"
        size="xl"
        label="Rede"
        :options="retail_chains"
        option-attribute="name"
        :loading="isRetailChainsLoading"
        :popper="{
          adaptive: true,
          overflowPadding: 12,
          strategy: 'absolute',
        }"
        @change="$emit('change', $event)"
      />
    </UFormGroup>
  </div>
</template>

<script setup lang="ts">
import { getRetailChainsService } from "~/services/retail-chains/get-retail-chains";
defineEmits(["change"]);

defineProps({
  form: {
    type: Object,
    required: true,
  },
});
const retail_chains = ref([]);
const isRetailChainsLoading = ref(false);
const axios = useAxios();

async function getRetailChains() {
  isRetailChainsLoading.value = true;
  try {
    const { data } = await getRetailChainsService(axios);
    retail_chains.value = data.data.retail_chains;
  } catch (error) {
    console.error(error);
  } finally {
    isRetailChainsLoading.value = false;
  }
}

onMounted(() => {
  getRetailChains();
});
</script>
